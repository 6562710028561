.product_card_container {
  // BC TODO
  border: none;

  .card-body {
    padding: 1rem 0rem;
    a {
      // color: $envie_black;
      color: #1c1b1b;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 900;
      transition: color 0.2s ease-in-out;
      // margin: 0px 1px;
    }
    h3 {
      padding: 0px;
      font-size: 13px;
      font-weight: 100;
      box-sizing: border-box !important;
      -webkit-font-smoothing: antialiased;
      text-transform: uppercase;
    }
  }
}
.envie_button {
  margin-top: 15px;
}

.keikei_button {
  @include btn_hover;
  @include color_8;

  &:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  border-radius: 50px;
  margin-left: 0px;
  margin-right: 0px;
}

.navbar-dark .navbar-nav {
  .nav-link {
    color: $envie_gray;

    &:focus,
    &:hover,
    &:active {
      color: $envie_black;
    }
  }
  .show > .nav-link {
    color: $envie_black;
  }
}

.form-inline {
  input,
  button {
    border-radius: 50px;
  }
}
.navbar svg {
  width: 100px;
  margin-right: 10px;
  padding-bottom: 10px;

  &:hover {
    cursor: pointer;
  }
  .cls-1 {
    fill: $envie_gray;
    &:hover {
      fill: #000;
    }
  }
}
.search_button {
  color: #000;
  color: #fff;
  margin: 0px;
  border-color: #000;
  width: inherit;
  font-size: inherit;

  &:hover {
    // color: rgb(4, 4, 4);
    background-color: #000;
    border-color: $envie_gray;
  }
}

// .search_button {
//   color: #000;
//   border: 1px solid #000;
//   background-color: #fff;
//   &:active {
//     color: #000;
//     color: red;
//     background-color: #fff;
//   }
//   &:focus {
//     color: #000;
//     color: blue;
//     background-color: #fff;

//     &:hover {
//       // color: rgb(4, 4, 4);
//       color: #fff;
//     }
//   }
//   &:hover {
//     // color: rgb(4, 4, 4);
//     background-color: #000;
//     border-color: $envie_gray;
//   }
// }

h3 {
  font-family: "Varela", sans-serif;
}

.product_image {
  width: 100%;
  height: auto;

  background-color: #cccccc;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.product_headline {
  margin: 50px;
}

video {
  /* override other styles to make responsive */
  width: 100% !important;
  height: auto !important;
}

.product_screen_card {
  border: none !important;
}

.nav_bar_wrapper {
  &.hover {
    cursor: pointer;
  }
}
/// PAYMENT METHOD

.payment_method {
  .form-label {
    display: flex;
    justify-content: center;
  }
}

// order screen
.wrap_h1 {
  word-wrap: break-word;
}

.background {
  body,
  .background {
    /* height: 100%; */
    width: 100%;
    background: #fff;
  }

  .categoryContainer {
    margin: 20px 0px;
    text-align: center;
  }

  .logic_divider {
    height: 5px;
    width: 100%;
    background: #000;
    margin: 20px 0px;
    text-align: center;
  } /* NAV */
  /* OVER RIDES */
  /* OVER RIDES */
  /* OVER RIDES */

  .navbar a {
    color: #505050;
    font-weight: 700;
  }

  .the-white-out {
    color: #505050 !important;
  }

  .iceWhite {
    color: #505050 !important;
  }

  .navbar-global .navbar-toggle li {
    display: block;
  }

  .navbar-global .navbar-toggle .icon-bar {
    background-color: #505050;
    background-color: #fff;
  }

  .nav > li > a:focus,
  .nav > li > a:hover {
    color: rgba(0, 0, 0, 0.5) !important;
  }

  .dropdown-header {
    color: #fff;
  }

  @media (max-width: 767px) {
    .dropdown-header {
      color: #505050;
    }

    .dropdown-menu li > a {
      color: #505050;
    }
  }
  .navbarContainer {
    display: block;
    position: relative;
    height: 100px;
  }

  .le_spacer {
    margin: 50px 0px;
  }

  .glass_button {
    float: right;
  }

  .backButton {
    margin: 10px 0px;
  }

  .backButton a i {
    font-size: 25px;
    font-weight: lighter;
    color: black;
  }
}

@font-face {
  font-family: "Rollgates";
  src: url("../../fonts/Rollgates-Luxury.otf") format("truetype");
}

// Variables
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
$g-font-family: "Roboto Condensed", sans-serif;
$Rollgates: "Rollgates", sans-serif;
.pre_launch_background_wrapper {
  // background-image: url('./uploads/blank_candle.jpg') !important;
  // auto: 10px;
  background-repeat: no-repeat !important;
  background-position: center !important;
  right: left;
  width: 100% !important;
  height: 100vh !important;
  min-height: 500px;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #fff !important;
  }
  h2,
  h1 {
    font-family: $Rollgates;

    // font-family: $g-font-family;
    // font-size: 50px;
  }
  h3 {
    font-size: 15px;
  }
  h6 {
    text-transform: lowercase;
    font-family: $Rollgates;
  }
  .logo_image {
    width: 350px;
    margin-top: 100px;
  }

  @media screen and (max-width: 1300px) {
    .logo_image {
      width: 300px;
      margin-top: 50px;
    }
  }
  .content_wrapper {
    background-color: rgba(0, 0, 0, 0.25);
    width: 100% !important;
    height: 100vh !important;
    min-height: 500px;
  }
}

// footer {
//   position: absolute;
//   bottom: 0;
//   width: 100%;
// }

// --- -PLAYGROUND 02/25 - GSAP

.title_carousel {
  // background-color: blue;
  // height: 100%;
  // width: fit-content;
  width: 100%;
  // display: inline;
  // position: fixed;
  // border-radius: 100%;
  font-size: 250px;
  text-transform: uppercase;
  // display: inline;
  position: relative;
}
.square {
  background-color: red;
  height: 4em;
  width: 4em;
}
// --- -PLAYGROUND 02/25 - GSAP
#firstCircle {
  background-color: yellow;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 10px;
  border: 2px solid blue;
  margin-bottom: 30%;
}

#secondCircle {
  background-color: blue;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 10px;
  border: 2px solid yellow;
  margin-bottom: 30%;
}

#thirdCircle {
  background-color: black;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin: 10px 0;
  border: 2px solid yellow;
}
// ---
.spacer {
  height: 400px;
}

.scrubs {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: middle;
  height: 50vh;
  margin: 200px 0;
}

.section {
  /*   height: 50%; */
}

.scene,
.scene2,
.scene3,
.scene4 {
  /*   height: 100%; */
  width: 50%;
  /*   background: white; */
  /*   @media screen and (max-width: 767px) {
    width: 100%; */
}

.viewer {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: auto;
  background-image: url(https://assets.codepen.io/687402/vid4_sprite_1.png?format=auto);
  background-repeat: no-repeat;
  background-size: cover;
}

.viewer2 {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 100%;
  background-image: url(https://assets.codepen.io/687402/vid2_sprite_1.png?format=auto);
  background-repeat: no-repeat;
  background-size: cover;
  /*   background-position: -150px 50%; */
}

.viewer3 {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 50%;
  background-image: url(https://cdn.shopify.com/s/files/1/0382/6466/8295/t/3/assets/vid4_sprite.png);
  background-repeat: no-repeat;
}

.viewer4 {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
  width: 50%;
  background-image: url(https://cdn.shopify.com/s/files/1/0382/6466/8295/t/3/assets/vid3_sprite.png);
  background-repeat: no-repeat;
}
